$(document).ready(function () {
    // Config toastr
    toastr.options = {
        closeButton: true,
        progressBar: true,
        showMethod: 'slideDown',
        timeOut: 4000
    };

    window.NotiError = (title, message) => toastr.error(message, title);
    window.NotiSuccess = (title, message) => toastr.success(message, title);
    window.NotiWarning = (title, message) => toastr.warning(message, title);

});

(function () {
    var handleErrorStatus = function (errorThrown, status) {
        switch (status) {
            case 401:
                location.assign('/Account/Login');
                break;

            case 403:
                location.assign('/Error/Unauthorized');
                break;

            case 404:
                location.assign('/Error/404');
                break;

            case 0:
                console.log('errorThrown : ' + errorThrown);
                break;

            default:
                NotiError(errorThrown, status);
                break;
        }
    };

    //User xhrPool to handle abort ajax calls
    $.xhrPool = []; // array of uncompleted requests

    $.xhrPool.abortAll = function () { // our abort function
        $(this).each((idx, jqXHR) => jqXHR.abort());
        $.xhrPool.length = 0;
    };

    $.ajaxSetup({
        beforeSend: jqXHR => $.xhrPool.push(jqXHR), // before jQuery send the request we will push it to our array
        complete(jqXHR) { // when some of the requests completed it will splice from the array
            var index = $.xhrPool.indexOf(jqXHR);
            
            if (index > -1) {
                $.xhrPool.splice(index, 1);
            }
        },
        error({ responseJSON, status }, textStatus, errorThrown) {
            if (textStatus === 'parsererror') {
                return undefined;
            }
            
            if (!responseJSON) {
                handleErrorStatus(errorThrown, status);
                return undefined;
            }

            var { Code, ErrorMessage } = responseJSON;

            handleErrorStatus(ErrorMessage, Code);
        },
        headers: {
            Authorization: `Bearer VSIP`,
            'languages': '_' + document.languages
        }
    });

    // Assign new token from cookie in case old token in be expired
    //$.ajaxPrefilter(options => {
    //    var tokenInHeaders = options.headers.Authorization.substring(7),
    //        tokenInCookie = $.cookie('accessToken');

    //    if (tokenInHeaders !== tokenInCookie) {
    //        Object.assign(options.headers, { Authorization: `Bearer ${tokenInCookie}` });
    //    }
    //});

    var PerformAjax = function (type, url, data = [], processData = true, contentType = 'application/json; charset=utf-8', dataType = 'json', async = true, moreOptions = {}) {
        return $.ajax({
            async,
            contentType,
            data: processData ? JSON.stringify(data) : data,
            dataType,
            type,
            url,
            ...moreOptions
        });
    };

    window.PerformAjaxGet = _.partial(PerformAjax, 'GET', _, _, false);
    window.PerformAjaxGetSync = _.partial(PerformAjax, 'GET', _, _, false, _, _, false)
    window.PerformAjaxPost = _.partial(PerformAjax, 'POST');
    window.PerformAjaxPostSync = _.partial(PerformAjax, 'POST', _, _, true, _, _, false);
    window.PerformAjaxPut = _.partial(PerformAjax, 'PUT');
    window.PerformAjaxDelete = _.partial(PerformAjax, 'DELETE', _, _, false);
    window.handleErrorStatus = handleErrorStatus;
}());